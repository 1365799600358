import React, { useEffect, useState } from 'react'
import AOS from "aos";
import Cta_Section from './Cta-section';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loader from './Loader';
import { MdFileDownload } from "react-icons/md";
import Fancybox from './FancyBox';
import { useProductContext } from '../context/CategoryContext';
import { useScrollToTop } from './UserScrollTop';

const ProductDetails = () => {
    const [screenLoading, setScreenLoading] = useState(false);
    const [randomProducts, setRandomProducts] = useState([]);
    const { selectedCategoryProducts } = useProductContext();
    useScrollToTop();
    const { id } = useParams();
    const productId = parseInt(id, 10);
    const product = selectedCategoryProducts.find((item) => item.id === productId);
    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    
    
        const getRelatedProducts = (selectedProduct, count) => {
            const relatedProducts = selectedCategoryProducts.filter((item) => {
                const isSameCategory = item.category_name === selectedProduct.category_name;
                const isSameCollection = selectedProduct.collection ? item.collection === selectedProduct.collection : true;
                // Exclude the current product
                const isNotCurrentProduct = item.id !== selectedProduct.id;
    
                return isSameCategory && isSameCollection && isNotCurrentProduct;
            });

            return relatedProducts.slice(0, count);
        };
    
        // Set related products if the product is found
        if (product) {
            setRandomProducts(getRelatedProducts(product, 4));
        }
    }, [selectedCategoryProducts, productId]);
    
    




    // const getRandomProducts = (count) => {
    //     const shuffledProducts = products.sort(() => Math.random() - 0.5);
    //     return shuffledProducts.slice(0, count);
    // };

    if (!product) {
        return (
            <div style={{ height: "90vh", color: "#000", display: "flex", justifyContent: "center", "alignItems": "center" }}>
                <h1>Loading......</h1>
            </div>);
    }



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                {/* <title>Countertops | products | quartz | marble | granite | quartzite </title>
                <meta name="description" content="Explore the vast range of surface products Astra Surfaces offers in New Jersey. Browse through the page to learn our latest quartz, marble, granite, and quartzite countertops in different designs, colors, textures, and more. Get your interior inspiration right here!" /> */}
            </Helmet>

            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <section className='product-details-container '>
                            <div className='product-detail-banner-section'>
                                {product.images.map((image) => (
                                    <img key={image.id} src={image.image_url} alt={image.product_name} />
                                ))}
                               
                            </div>

                            <div className='container'>
                                <div className='gap'>
                                    <div className='row d-flex justify-content-center mb-5'>
                                        <div className="col-lg-9 col-12">
                                            <p className='text-center text-md-start'><Link className="link" to={'/products'}>Products</Link> / <Link to={`/products/${product.category_name.replace(/\s+/g, '-')}`} className="link">{product.category_name} {product.category_name === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                                            </Link>
                                                {product.subcategory_name && (
                                                    <>
                                                        / <Link to={`/products/${product.subcategory_name.replace(/\s+/g, '-')}`} className="link">{product.subcategory_name}</Link>
                                                    </>
                                                )}
                                                / <Link className="link">{product.product_name}</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row gx-lg-5 d-flex justify-content-center">
                                        <div className="col-lg-3 col-md-4 ">
                                            <Fancybox
                                                options={{
                                                    Carousel: {
                                                        infinite: false,
                                                    },
                                                }}
                                            >
                                                {product.images.map((image) => (
                                                    <div className="image-wrapper d-md-block d-flex justify-content-center" data-fancybox="product-image" data-src={image.image_url} key={image.id}>
                                                        <img src={image.image_url} alt={image.product_name} className='product-img' />
                                                    </div>

                                                ))}
                                                {/* <div className="image-wrapper d-md-block d-flex justify-content-center" data-fancybox="product-image" data-src={product.preview}>
                                                    <img src={product.preview} alt="product-url" className='product-img' />
                                                </div> */}
                                            </Fancybox>
                                        </div>
                                        <div className="col-lg-6 col-md-8 my-auto">
                                            <div>
                                                <h1 className='mb-lg-4 mb-2'>{product.product_name}</h1>
                                                {/* <p className='py-md-3 text-md-start text-center'>
                                                    Simple, minimal veining on a clean white background.
                                                </p> */}
                                                <div className='d-md-block d-flex justify-content-center'>
                                                    <Link to={"/contact-us"}><button className='product-contact-btn'>Contact Us</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ "borderTop": '4px solid rgba(0,0,0,0.6)' }} className='gap' />
                                <div className="gap">
                                    <div className="row">
                                        <div className="col-lg-6 my-auto">
                                            <div className="product-details-wrapper">
                                                <h4 className='mb-3 '>Specifications</h4>
                                                <table>
                                                    <tbody>
                                                        {/* <tr>
                                                        <td>Type</td>
                                                        <td>{product.type}</td>
                                                    </tr> */}
                                                        {product.category_name && <tr>
                                                            <td>Category</td>
                                                            <td>{product.category_name}</td>
                                                        </tr>}
                                                        {product.thickness && <tr>
                                                            <td>Thickness</td>
                                                            <td>{product.thickness}</td>
                                                        </tr>}
                                                        {product.color && <tr>
                                                            <td>Color</td>
                                                            <td>{product.color}</td>
                                                        </tr>}
                                                        {product.collection && <tr>
                                                            <td>Collection</td>
                                                            <td>{product.collection}</td>
                                                        </tr>}
                                                        {product.vein_color && <tr>
                                                            <td>Vein Color</td>
                                                            <td>{product.vein_color}</td>
                                                        </tr>}
                                                        {product.finish && <tr>
                                                            <td>Finish</td>
                                                            <td>{product.finish}</td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <Fancybox
                                                options={{
                                                    Carousel: {
                                                        infinite: false,
                                                    },
                                                }}
                                            >
                                                <div className="preview-image" data-fancybox="preview" data-src={product.preview_url}>
                                                    <img src={product.preview_url} alt="" className='product-preview' />
                                                </div>
                                            </Fancybox>
                                        </div>
                                    </div>
                                    <section className='similar-products gap mb-5'>
                                        <div className='container'>
                                            <h3>You May Also Like</h3>
                                            <div className="row">
                                                {
                                                    randomProducts.map((randomProduct) => (
                                                        <div className="col-lg-3" key={randomProduct.id}>
                                                            <Link
                                                                to={`/product-details/${randomProduct.id}`}
                                                                className="link"
                                                            >
                                                                <div className="image-wrapper">
                                                                    {randomProduct.images.map((image) => (
                                                                        <img src={image.image_url} key={image.id} alt={image.product_name} />
                                                                    ))}

                                                                </div>
                                                                <h6>{randomProduct.product_name}</h6>
                                                            </Link>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                        <div className="gap">
                            <Cta_Section />
                        </div>
                    </>
                }
            </>
        </>
    )
}

export default ProductDetails