import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import { Helmet } from 'react-helmet';
import { useScrollToTop } from './UserScrollTop';

const Faq = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Variant Surfaces | FAQ</title>
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <section id='faq' className='gap-1'>
                        <div className="section-2">
                            <div className="row">
                                <div className="col-lg-8 col-md-10 mx-auto">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Does Variant Surfaces offer quartz countertops in Dallas?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Yes. We offer a vast range of kitchen countertop options including quartz countertops and natural stone countertops- granite, marble, and quartzite. We also have porcelain slabs for residential and commercial projects.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    In what areas of the home can I use countertops?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        You can use the slabs as kitchen countertops, kitchen islands, bathroom vanity tops, and waterfall islands. You can also use countertops for outdoor kitchens. Countertops are a great option for fireplaces, shower edges, window sills, and coffee tables.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Can I use a countertop in my office?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Countertops are a famous choice for commercial applications. You can use countertops for food service countertops, conference tables, worktops, reception tops, and more. With our designs and countertop collection, the possibilities are endless.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    What’s a better choice for my kitchen countertop: quartz or granite?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Granite and quartz are aesthetic and durable options for countertops. Ultimately, the decision is all yours. So whether you need a quartz countertop or a granite top for your space, Variant Surfaces has all the options you need to select the best countertop for your home space.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Can I get a quartz countertop for my kitchen?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Our showroom showcases various quartz countertops in numerous designs, patterns, and colors. Visit us, and our team will guide you in choosing the best countertop for your space.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        </>
    )
}

export default Faq