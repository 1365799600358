import React, { useEffect, useState } from 'react'

// Header style
import './Header.css'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useProductContext } from '../context/CategoryContext';
import { Dropdown } from 'bootstrap';
const Header = () => {
    const [headerHeight, setHeaderHeight] = useState(130);
    const { updateSelectedCategory, productsCategory, selectedCategory, fetchProductsByCategory } = useProductContext();
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isActive = location.pathname === '/products';
    const [isCollapsed, setIsCollapsed] = useState(true);


    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const closeNavbar = () => {
        setIsCollapsed(true);
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 100;
            const newHeaderHeight = window.scrollY > scrollThreshold ? 100 : 130;

            setHeaderHeight(newHeaderHeight);
        };

        const handleResize = () => {
            // You can adjust the breakpoint as needed
            const mobileBreakpoint = 600;
            setIsMobile(window.innerWidth <= mobileBreakpoint);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleCategoryClick = (category) => {
        updateSelectedCategory(category);
        // setIsDropdownOpen(false);
        closeNavbar();
    };
    const handleClick = async (category) => {
        await fetchProductsByCategory(category.category_url);
        updateSelectedCategory(category)
        closeNavbar();
    }
    const handleNavigation = (category) => {
        // First navigate
        navigate(`/products`);


        const dropdownElement = document.querySelector('.dropdown-toggle.nav-link');
        // Get the bootstrap dropdown instance
        const dropdownInstance = Dropdown.getOrCreateInstance(dropdownElement);
        // Hide the dropdown
        dropdownInstance.hide();
        closeNavbar();
    }
    const handleResourcesNavigation = (category) => {
        // First navigate
        navigate(`/resources`);


        const dropdownElement = document.querySelector('.dropdown-toggle.nav-link');
        // Get the bootstrap dropdown instance
        const dropdownInstance = Dropdown.getOrCreateInstance(dropdownElement);
        // Hide the dropdown
        dropdownInstance.hide();
        closeNavbar();
    }
    return (
        <>
            <nav
                className="navbar navbar-expand-lg py-2 fixed-top px-lg-5 px-2"
                style={{ minHeight: `${headerHeight}px`, background: "#fff" }}
            >
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <img src="/Variant surfaces logo Black.png" alt="logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className={`collapse navbar-collapse  justify-content-end ${isCollapsed ? "" : "show"}`}
                        id="navbarNav"
                    >
                        <ul className="navbar-nav">
                            <li className="nav-item my-auto dropdown custom-nav-dropdown">
                                <a
                                    className="dropdown-toggle nav-link"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => handleNavigation('')}
                                >
                                    Products
                                </a>
                                <ul className="dropdown-menu">
                                    {productsCategory.map((category) => (
                                        <li key={category.id}>
                                            <NavLink
                                                to={`/products/${category.category.replace(
                                                    /\s+/g,
                                                    "-"
                                                )}`}

                                                className={({ isActive }) =>
                                                    (selectedCategory.category === category.category && location.pathname.includes('/products/')) ? "active dropdown-item" : "dropdown-item"
                                                }
                                                // className="dropdown-item"
                                                onClick={() => handleClick(category)}
                                            >
                                                {category.category}
                                                {category.category === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item my-auto dropdown custom-nav-dropdown">
                                <a
                                    className="dropdown-toggle nav-link"

                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => handleResourcesNavigation('')}
                                >
                                    Resources
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink
                                            to={"/catalogs"}
                                            className="dropdown-item"
                                            onClick={closeNavbar}
                                        >
                                            Catalogs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={"/faq"}
                                            className="dropdown-item"
                                            onClick={closeNavbar}
                                        >
                                            FAQ
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={"/warranty-registration"}
                                            className="dropdown-item"
                                            onClick={closeNavbar}
                                        >
                                            Warranty
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item my-auto">
                                <NavLink
                                    to={"/blogs"}
                                    className={({ isActive }) =>
                                        isActive ? "active nav-link" : "nav-link"
                                    }
                                    onClick={closeNavbar}
                                >
                                    Blog
                                </NavLink>
                            </li>
                            <li className="nav-item my-auto">
                                <NavLink
                                    to={"/design-gallery"}
                                    className={({ isActive }) =>
                                        isActive ? "active nav-link" : "nav-link"
                                    }
                                    onClick={closeNavbar}
                                >
                                    Design Gallery
                                </NavLink>
                            </li>
                            <li className="nav-item my-auto">
                                <NavLink
                                    to={"/about-us"}
                                    className={({ isActive }) =>
                                        isActive ? "active nav-link" : "nav-link"
                                    }
                                    onClick={closeNavbar}
                                >
                                    About Us
                                </NavLink>
                            </li>

                            <li className="nav-item my-auto nav-button">
                                <Link to={"/contact-us"}><button>Contact Us</button></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header

