import React, { useEffect, useState } from 'react'
import AOS from "aos";
import { Helmet } from "react-helmet";
import Banner from './Banner'
import Loader from './Loader';
import { MdFileDownload } from "react-icons/md";
import { useScrollToTop } from './UserScrollTop';

const Catalogs = () => {
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
    setScreenLoading(false);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
  }, []);

  useScrollToTop();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Variant Surfaces | Catalogs</title>
      </Helmet>
      <>
        {screenLoading ? (
          <Loader />
        ) :
          <>
            <Banner name="Catalogs" url="assests/images/GIOTTO 002.webp" />
            <section className='catalog-section gap'>
              <div className="container">
                <h2>Our Catalogs</h2>
                <div className="row mt-5 justify-content-center">
                  <div className="col-md-6 mb-5">
                    <div className="catalogue_card">
                      <div className="catalogue_img">
                        <img src="assests/catalogs/variant care & maintenance.png" alt="" />
                      </div>
                      <div className='catalogue_name'>
                        <p className='my-auto'>
                          Care & Maintenance
                        </p>
                        <a href="assests/catalogs/VS_Care_Maintenance_Manual.pdf" target="_blank"><MdFileDownload className='download-icon' /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="catalogue_card">
                      <div className="catalogue_img">
                        <img src="assests/catalogs/Variant fabrication and installation guide.png" alt="" />
                      </div>
                      <div className='catalogue_name'>
                        <p className='my-auto'>
                          Fabrication & Installation Guide
                        </p>
                        <a href="assests/catalogs/VS_Fabrication_and_Installation_Guide_2022-Update.pdf" target="_blank"><MdFileDownload className='download-icon' /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="catalogue_card">
                      <div className="catalogue_img">
                        <img src="assests/catalogs/Variant VS_Product_Specifications.png" alt="" />
                      </div>
                      <div className='catalogue_name'>
                        <p className='my-auto'>
                          Product Specifications
                        </p>
                        <a href="assests/catalogs/VS_Product_Specifications.pdf" target="_blank"><MdFileDownload className='download-icon' /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="catalogue_card">
                      <div className="catalogue_img">
                        <img src="assests/catalogs/Variant VS_Safety_Data.png" alt="" />
                      </div>
                      <div className='catalogue_name'>
                        <p className='my-auto'>
                          Safety Data
                        </p>
                        <a href="assests/catalogs/VS_Safety_Data.pdf" target="_blank"><MdFileDownload className='download-icon' /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      </>
    </>
  )
}

export default Catalogs