import React, { useEffect, useState } from "react";
import Fancybox from "./FancyBox";
import AOS from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import "swiper/css";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { useProductContext } from "../context/CategoryContext";
import { useScrollToTop } from "./UserScrollTop";
import Cta_Section from "./Cta-section";

const Home = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const { productsCategory, updateSelectedCategory, fetchProductsByCategory } = useProductContext();

  useEffect(() => {
    AOS.init({ duration: 1200 });
    AOS.refresh();
    setScreenLoading(false);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
  }, []);

  const handleClick = async (category) => {
    await fetchProductsByCategory(category.category_url);
    updateSelectedCategory(category)
  };

  useScrollToTop()
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Marble, Granite, Quartz Countertops in Dallas, TX | Kitchen
          countertops |
        </title>
        <meta
          name="description"
          content="Variant Surfaces offers the best kitchen countertops in Dallas, Texas. Our vast range of countertop surfaces includes granite, quartzite, marble, quartz, porcelain, and more. Visit our Dallas showroom to upgrade your kitchen, bathroom, and other surfaces with our best Dallas countertops."
        />
        <meta
          name="keywords"
          content="Kitchen countertops in Dallas, TX/Txs,Countertops in Dallas, Texas,Quartz countertops in Dallas,Custom countertops,Granite and quartz countertops Dallas,Buy granite, marble or quartz countertops in Dallas,Dallas showroom,Marble countertops in Dallas,countertop distributor near Dallas,Countertop company,Dallas countertop,countertop collection"
        />
      </Helmet>
      <>
        {screenLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <section className="hero-section">
                <Swiper className="mySwiper" loop={true}>
                  <SwiperSlide>
                    <div className="">
                      <img
                        src="assests/images/BLUE-DEEP-004.webp"
                        alt="banner-1"
                        className="hero-image"
                      />
                      <div className="overlay"></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <img
                        src="assests/images/CRYSTALLO-0004.webp"
                        alt="banner-2"
                        className="hero-image"
                      />
                      <div className="overlay"></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <img
                        src="assests/images/MONT BLANC QUARTZITE 001.webp"
                        alt="banner-3"
                        className="hero-image"
                      />
                      <div className="overlay"></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="">
                      <img
                        src="assests/images/Arabescato Corchia Marble 001.webp"
                        alt="banner-4"
                        className="hero-image"
                      />
                      <div className="overlay"></div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="text-wrapper">
                  <h3>Surfaces that make a statement</h3>
                  <p>
                    Variant Surfaces offers globally sourced natural stone, quartz, porcelain surfaces, and
                    more. Visit our showrooms to upgrade your kitchen, bathroom, and other surfaces.
                  </p>
                </div>
              </section>


              <section className="product-section gap">
                <div className="container">
                  {/* <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                    }}
                  > */}
                  <div className="row mt-md-5 mt-3 gx-3 justify-content-center">
                    {productsCategory &&
                      productsCategory.map((category) => {
                        let overlayText = "";
                        if (category.category === "Variant Quartz") {
                          overlayText = "Our Quartz slabs are Scratch, Stain and Heat resistance";
                        } else if (category.category === "Variant Porcelain™") {
                          overlayText = "Each colors has been selected with the great care to details to covey the Natural stone beauty to the porcelain surface";
                        } else if (category.category === "Natural Stone") {
                          overlayText = "Vast Range of Natural stones includes Granite, Marble, Quartzite, Soapstone and more";
                        }
                        return (
                          <div
                            className="col-lg-3 col-md-4  mb-md-0 mb-3"
                            data-aos="fade-up"
                          >
                            <h3>{category.category} {category.category === "Variant Quartz" && <sup className="registeredTrademark">®</sup>}</h3>
                            <Link
                              to={`/products/${category.category.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              onClick={() => handleClick(category)}
                            >
                              <div
                                className="image-wrapper"
                                data-fancybox="gallery"
                              // data-src={category.image}
                              >
                                <img
                                  src={category.image}
                                  alt={category.category}
                                  className="image-1"
                                />
                                <div className="overlay">
                                  <p>{overlayText}</p>
                                </div>
                              </div>
                            </Link>
                            <Link
                              to={`/products/${category.category.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              onClick={() => handleClick(category)}
                            >
                              <button className="product-btn">
                                View Product
                              </button>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                  {/* </Fancybox> */}
                </div>
              </section>

              <section className="section-10 gap">
                <div className="bg-color">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="text-wrapper">
                          <h3>
                            Personally Curate your Environment
                          </h3>
                          <p className="my-4">
                            Our showroom is open to the public. Stop by and explore our vast inventory to find the material
                            that fits your needs.
                          </p>
                          <Link to={"/products"}><button>EXPLORE</button></Link>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-lg-0 mt-3">
                        <div className="d-flex">
                          <img src="assests/images/Home-Icon-01-1.png" alt="" className="icon" />
                          <p className="my-auto">
                            Our knowledgeable and welcoming staff will assist you in selecting the ideal slab.
                          </p>
                        </div>
                        <div className="d-flex">
                          <img src="assests/images/Home-Icon-02-1.png" alt="" className="icon" />
                          <p className="my-auto">
                            We will hold your slabs for 2 weeks once selected.
                          </p>
                        </div>
                        <div className="d-flex">
                          <img src="assests/images/Home-Icon-03-1.png" alt="" className="icon" />
                          <p className="my-auto">
                            After completing the purchase from Variant Surfaces, your fabricator will collaborate
                            with you to install the slab.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-11 gap">
                <div className="container">
                  <div className="row gx-5">
                    <div className="col-lg-6">
                      <div className="image-wrapper">
                        <Swiper className="mySwiper2" loop={true} autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                          modules={[Autoplay]}
                        >
                          <SwiperSlide>
                            <div className="">
                              <img
                                src="assests/images/CALACATTA MIST 002.webp"
                                alt="banner-1"
                                className="swiper-image"
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="">
                              <img
                                src="assests/images/POLARIS 002.webp"
                                alt="banner-2"
                                className="swiper-image"
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="">
                              <img
                                src="assests/images/SUPER WHITE 002.webp"
                                alt="banner-3"
                                className="swiper-image"
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="">
                              <img
                                src="assests/images/MONT BLANC QUARTZITE 001.webp"
                                alt="banner-4"
                                className="swiper-image"
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="">
                              <img
                                src="assests/images/EXPLOSION CRYSTAL 2153 001.webp"
                                alt="banner-5"
                                className="swiper-image"
                              />
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="">
                              <img
                                src="assests/images/Cristazzo 001.webp"
                                alt="banner-6"
                                className="swiper-image"
                              />
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-3 d-flex justify-content-center align-items-center">
                      <div className="text-wrapper ">
                        <h3>About Us</h3>
                        <p className="my-4">
                          Variant Surfaces is owned and operated by experts in the industry. Click below to learn more
                          about us:
                        </p>
                        <div ><Link to={"/about-us"}><button>ABOUT US...</button></Link></div>
                        {/* <div className="mt-4"><Link to={"/contact-us"}><button>CONTACT US</button></Link></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>



              <div className="gap">
                <Cta_Section />
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default Home;
