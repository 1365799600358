import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import Loader from './Loader';
import { useScrollToTop } from './UserScrollTop';

const Blogs = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Variant Surfaces - Blogs</title>
                <meta name="description" content="Want some insightful information about countertops? Here are some of the fresh articles with trends, maintenance tips, design inspirations, and more to give an upgrade to your space. Explore now! " />
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <Banner name="Blogs" url="assests/images/CALACATTA ARABASCATO 002.webp" />
                        <section className='blog-pag gap'>
                            <div className="container">
                                <div className="row gx-0 d-flex justify-content-center mb-5">
                                    <div className="col-lg-10" data-aos="fade-left">
                                        <div className="row blog gx-0">
                                            <div className="col-lg-4">
                                                <div className="image-wrapper">
                                                <img src="assests/images/Macchia-antica-1-scaled.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 my-auto blog-content border">
                                                <h2>Beyond Kitchen: 7 Applications Of Countertop Materials</h2>
                                                <hr />
                                                <p>
                                                    Why limit these stunning kitchen countertop materials to only culinary space? Let your imagination run, and find the best ways to use countertop slabs beyond the kitchen.
                                                </p>
                                                <Link to={"/beyond-kitchen-7-applications-of-countertop-materials"}><button>Read More..</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 mt-lg-5" data-aos="fade-right">
                                        <div className="row blog gx-0">
                                            <div className="col-lg-4">
                                                <div className="image-wrapper">
                                                <img src="assests/images/cristallo-rose-scaled.jpeg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 my-auto blog-content border">
                                                <h2>7 Types Of Countertops For Your Kitchen: Find Your Ultimate Match</h2>
                                                <hr />
                                                <p>
                                                    Confused about which type of countertop to use in your next kitchen renovation project? No worries, here we have drafted some most sought-after kitchen countertop materials, which are durable, versatile, and yeah aesthetically appealing. So read on and know which will suit your interiors the best.
                                                </p>
                                                <Link to={"/different-types-of-kitchen-countertops"}><button>Read More..</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </>
        </>
    )
}

export default Blogs